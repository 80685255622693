// REMIX HMR BEGIN
if (!window.$RefreshReg$ || !window.$RefreshSig$ || !window.$RefreshRuntime$) {
  console.warn('remix:hmr: React Fast Refresh only works when the Remix compiler is running in development mode.');
} else {
  var prevRefreshReg = window.$RefreshReg$;
  var prevRefreshSig = window.$RefreshSig$;
  window.$RefreshReg$ = (type, id) => {
    window.$RefreshRuntime$.register(type, "\"app/components/primitives/Buttons.tsx\"" + id);
  }
  window.$RefreshSig$ = window.$RefreshRuntime$.createSignatureFunctionForTransform;
}
var _s = $RefreshSig$(),
  _s2 = $RefreshSig$();
import * as __hmr__ from "remix:hmr";
if (import.meta) {
  import.meta.hot = __hmr__.createHotContext(
  //@ts-expect-error
  "app/components/primitives/Buttons.tsx");
  import.meta.hot.lastModified = "1734197435729.4458";
}
// REMIX HMR END

import { Link, NavLink } from "@remix-run/react";
import React, { forwardRef, useImperativeHandle, useRef } from "react";
import { useShortcutKeys } from "~/hooks/useShortcutKeys";
import { cn } from "~/utils/cn";
import { NamedIcon } from "./NamedIcon";
import { ShortcutKey } from "./ShortcutKey";
import { Tooltip, TooltipContent, TooltipProvider, TooltipTrigger } from "./Tooltip";
const sizes = {
  small: {
    button: "h-6 px-2.5 text-xs",
    icon: "h-3.5 -mx-1",
    iconSpacing: "gap-x-2.5",
    shortcutVariant: "small",
    shortcut: "-ml-0.5 -mr-1.5 justify-self-center"
  },
  medium: {
    button: "h-8 px-3 text-sm",
    icon: "h-4 -mx-1",
    iconSpacing: "gap-x-2.5",
    shortcutVariant: "medium",
    shortcut: "-ml-0.5 -mr-1.5 rounded justify-self-center"
  },
  large: {
    button: "h-10 px-2 text-base font-medium",
    icon: "h-5",
    iconSpacing: "gap-x-0.5",
    shortcutVariant: "medium",
    shortcut: "ml-1.5 -mr-0.5"
  },
  "extra-large": {
    button: "h-12 px-2 text-base font-medium",
    icon: "h-5",
    iconSpacing: "gap-x-0.5",
    shortcutVariant: "medium",
    shortcut: "ml-1.5 -mr-0.5"
  }
};
const theme = {
  primary: {
    textColor: "text-charcoal-900 group-hover/button:text-charcoal-900 transition group-disabled/button:text-charcoal-900",
    button: "bg-primary group-hover/button:bg-apple-200 group-disabled/button:opacity-50 group-disabled/button:bg-primary group-disabled/button:pointer-events-none",
    shortcut: "border-black/40 text-charcoal-900 group-hover/button:border-black/60 group-hover/button:text-charcoal-900",
    icon: "text-charcoal-900"
  },
  secondary: {
    textColor: "text-text-bright transition group-disabled/button:text-text-dimmed/80",
    button: "bg-secondary group-hover/button:bg-charcoal-600 group-hover/button:border-charcoal-650 border border-charcoal-600 group-disabled/button:bg-secondary group-disabled/button:opacity-60 group-disabled/button:pointer-events-none",
    shortcut: "border-text-dimmed/40 text-text-dimmed group-hover/button:text-text-bright group-hover/button:border-text-dimmed",
    icon: "text-text-bright"
  },
  tertiary: {
    textColor: "text-text-bright transition group-disabled/button:text-text-dimmed/80",
    button: "bg-tertiary group-hover/button:bg-charcoal-600 group-disabled/button:bg-tertiary group-disabled/button:opacity-60 group-disabled/button:pointer-events-none",
    shortcut: "border-text-dimmed/40 text-text-dimmed group-hover/button:text-text-bright group-hover/button:border-text-dimmed",
    icon: "text-text-bright"
  },
  minimal: {
    textColor: "text-text-dimmed group-disabled/button:text-text-dimmed transition",
    button: "bg-transparent group-hover/button:bg-tertiary disabled:opacity-50 group-disabled/button:bg-transparent group-disabled/button:pointer-events-none",
    shortcut: "border-dimmed/40 text-text-dimmed group-hover/button:text-text-bright/80 group-hover/button:border-dimmed/60",
    icon: "text-text-dimmed"
  },
  danger: {
    textColor: "text-text-bright group-hover/button:text-white transition group-disabled/button:text-text-bright/80",
    button: "bg-error group-hover/button:bg-rose-500 disabled:opacity-50 group-disabled/button:bg-error group-disabled/button:pointer-events-none",
    shortcut: "border-text-bright text-text-bright group-hover/button:border-bright/60",
    icon: "text-text-bright"
  },
  docs: {
    textColor: "text-blue-200/70 transition group-disabled/button:text-text-dimmed/80",
    button: "bg-charcoal-700 border border-charcoal-600/50 shadow group-hover/button:bg-charcoal-650 group-disabled/button:bg-tertiary group-disabled/button:opacity-60 group-disabled/button:pointer-events-none",
    shortcut: "border-text-dimmed/40 text-text-dimmed group-hover/button:text-text-bright group-hover/button:border-text-dimmed",
    icon: "text-blue-500"
  }
};
function createVariant(sizeName, themeName) {
  return {
    textColor: theme[themeName].textColor,
    button: cn(sizes[sizeName].button, theme[themeName].button),
    icon: cn(sizes[sizeName].icon, theme[themeName].icon),
    iconSpacing: sizes[sizeName].iconSpacing,
    shortcutVariant: sizes[sizeName].shortcutVariant,
    shortcut: cn(sizes[sizeName].shortcut, theme[themeName].shortcut)
  };
}
const variant = {
  "primary/small": createVariant("small", "primary"),
  "primary/medium": createVariant("medium", "primary"),
  "primary/large": createVariant("large", "primary"),
  "primary/extra-large": createVariant("extra-large", "primary"),
  "secondary/small": createVariant("small", "secondary"),
  "secondary/medium": createVariant("medium", "secondary"),
  "secondary/large": createVariant("large", "secondary"),
  "secondary/extra-large": createVariant("extra-large", "secondary"),
  "tertiary/small": createVariant("small", "tertiary"),
  "tertiary/medium": createVariant("medium", "tertiary"),
  "tertiary/large": createVariant("large", "tertiary"),
  "tertiary/extra-large": createVariant("extra-large", "tertiary"),
  "minimal/small": createVariant("small", "minimal"),
  "minimal/medium": createVariant("medium", "minimal"),
  "minimal/large": createVariant("large", "minimal"),
  "minimal/extra-large": createVariant("extra-large", "minimal"),
  "danger/small": createVariant("small", "danger"),
  "danger/medium": createVariant("medium", "danger"),
  "danger/large": createVariant("large", "danger"),
  "danger/extra-large": createVariant("extra-large", "danger"),
  "docs/small": createVariant("small", "docs"),
  "docs/medium": createVariant("medium", "docs"),
  "docs/large": createVariant("large", "docs"),
  "docs/extra-large": createVariant("extra-large", "docs"),
  "menu-item": {
    textColor: "text-text-bright px-1",
    button: "h-9 px-[0.475rem] text-sm rounded-sm bg-transparent group-hover/button:bg-charcoal-750",
    icon: "h-5",
    iconSpacing: "gap-x-0.5",
    shortcutVariant: undefined,
    shortcut: undefined
  },
  "small-menu-item": {
    textColor: "text-text-bright",
    button: "h-[1.8rem] px-[0.4rem] text-2sm rounded-sm text-text-dimmed bg-transparent group-hover/button:bg-charcoal-750",
    icon: "h-4",
    iconSpacing: "gap-x-1.5",
    shortcutVariant: undefined,
    shortcut: undefined
  },
  "small-menu-sub-item": {
    textColor: "text-text-dimmed",
    button: "h-[1.8rem] px-[0.5rem] ml-5 text-2sm rounded-sm text-text-dimmed bg-transparent group-hover/button:bg-charcoal-750 focus-custom",
    icon: undefined,
    iconSpacing: undefined,
    shortcutVariant: undefined,
    shortcut: undefined
  }
};
const allVariants = {
  $all: "font-normal text-center font-sans justify-center items-center shrink-0 transition duration-150 rounded-[3px] select-none group-focus/button:outline-none group-disabled/button:opacity-75 group-disabled/button:pointer-events-none focus-custom",
  variant: variant
};
export function ButtonContent(props) {
  const {
    children: text,
    LeadingIcon,
    TrailingIcon,
    trailingIconClassName,
    leadingIconClassName,
    shortcut,
    fullWidth,
    textAlignLeft,
    className,
    showTooltip,
    tooltipDescription
  } = props;
  const variation = allVariants.variant[props.variant];
  const btnClassName = cn(allVariants.$all, variation.button);
  const iconClassName = variation.icon;
  const iconSpacingClassName = variation.iconSpacing;
  const shortcutClassName = variation.shortcut;
  const textColorClassName = variation.textColor;
  const renderShortcutKey = () => shortcut && <ShortcutKey className={cn(shortcutClassName)} shortcut={shortcut} variant={variation.shortcutVariant ?? "medium"} />;
  const buttonContent = <div className={cn("flex", fullWidth ? "" : "w-fit text-xxs", btnClassName, className)}>
      <div className={cn(textAlignLeft ? "text-left" : "justify-center", "flex w-full items-center", iconSpacingClassName)}>
        {LeadingIcon && (typeof LeadingIcon === "string" ? <NamedIcon name={LeadingIcon} className={cn(iconClassName, leadingIconClassName, "shrink-0 justify-start", variation.icon)} /> : <LeadingIcon className={cn(iconClassName, variation.icon, leadingIconClassName, "shrink-0 justify-start")} />)}

        {text && (typeof text === "string" ? <span className={cn("mx-auto grow self-center truncate", textColorClassName)}>
              {text}
            </span> : <>{text}</>)}

        {shortcut && !showTooltip && props.shortcutPosition === "before-trailing-icon" && renderShortcutKey()}

        {TrailingIcon && (typeof TrailingIcon === "string" ? <NamedIcon name={TrailingIcon} className={cn(iconClassName, trailingIconClassName, "shrink-0 justify-end", variation.icon)} /> : <TrailingIcon className={cn(iconClassName, variation.icon, trailingIconClassName, "shrink-0 justify-end")} />)}

        {shortcut && !showTooltip && (!props.shortcutPosition || props.shortcutPosition === "after-trailing-icon") && renderShortcutKey()}
      </div>
    </div>;
  if (shortcut && showTooltip) {
    return <TooltipProvider>
        <Tooltip>
          <TooltipTrigger asChild>{buttonContent}</TooltipTrigger>
          <TooltipContent className="text-dimmed flex items-center gap-3 py-1.5 pl-2.5 pr-3 text-xs">
            {tooltipDescription} {renderShortcutKey()}
          </TooltipContent>
        </Tooltip>
      </TooltipProvider>;
  }
  return buttonContent;
}
_c = ButtonContent;
export const Button = _s(forwardRef(_c2 = _s(({
  type,
  disabled,
  autoFocus,
  onClick,
  ...props
}, ref) => {
  _s();
  const innerRef = useRef(null);
  useImperativeHandle(ref, () => innerRef.current);
  if (props.shortcut) {
    useShortcutKeys({
      shortcut: props.shortcut,
      action: () => {
        if (innerRef.current) {
          innerRef.current.click();
        }
      },
      disabled
    });
  }
  return <button className={cn("group/button outline-none focus-custom", props.fullWidth ? "w-full" : "")} type={type} disabled={disabled} onClick={onClick} name={props.name} value={props.value} ref={innerRef} form={props.form} autoFocus={autoFocus}>
        <ButtonContent {...props} />
      </button>;
}, "WXBdk+HRr0R9QMKxCHmelJJptJY=", false, function () {
  return [useShortcutKeys];
})), "WXBdk+HRr0R9QMKxCHmelJJptJY=", false, function () {
  return [useShortcutKeys];
});
_c3 = Button;
export const LinkButton = ({
  to,
  onClick,
  onMouseDown,
  onMouseEnter,
  onMouseLeave,
  download,
  disabled = false,
  ...props
}) => {
  _s2();
  const innerRef = useRef(null);
  if (props.shortcut) {
    useShortcutKeys({
      shortcut: props.shortcut,
      action: () => {
        if (innerRef.current) {
          innerRef.current.click();
        }
      }
    });
  }
  if (disabled) {
    return <div className={cn("group/button pointer-events-none cursor-default opacity-40 outline-none", props.fullWidth ? "w-full" : "")}>
        <ButtonContent {...props} />
      </div>;
  }
  if (to.toString().startsWith("http") || to.toString().startsWith("/resources")) {
    return <ExtLink href={to.toString()} ref={innerRef} className={cn("group/button focus-custom", props.fullWidth ? "w-full" : "")} onClick={onClick} onMouseDown={onMouseDown} onMouseEnter={onMouseEnter} onMouseLeave={onMouseLeave} download={download}>
        <ButtonContent {...props} />
      </ExtLink>;
  } else {
    return <Link to={to} ref={innerRef} className={cn("group/button focus-custom", props.fullWidth ? "w-full" : "")} onClick={onClick} onMouseDown={onMouseDown} onMouseEnter={onMouseEnter} onMouseLeave={onMouseLeave} download={download}>
        <ButtonContent {...props} />
      </Link>;
  }
};
_s2(LinkButton, "todWE++oEyFQKNf13qf9hBUCScU=", false, function () {
  return [useShortcutKeys];
});
_c4 = LinkButton;
export const NavLinkButton = ({
  to,
  className,
  target,
  ...props
}) => {
  return <NavLink to={to} className={cn("group/button outline-none", props.fullWidth ? "w-full" : "")} target={target}>
      {({
      isActive,
      isPending
    }) => <ButtonContent className={className && className({
      isActive,
      isPending
    })} {...props} />}
    </NavLink>;
};
_c5 = NavLinkButton;
const ExtLink = forwardRef(_c6 = ({
  className,
  href,
  children,
  ...props
}, ref) => {
  return <a className={cn(className)} target="_blank" rel="noopener noreferrer" href={href} ref={ref} {...props}>
        {children}
      </a>;
});
_c7 = ExtLink;
var _c, _c2, _c3, _c4, _c5, _c6, _c7;
$RefreshReg$(_c, "ButtonContent");
$RefreshReg$(_c2, "Button$forwardRef");
$RefreshReg$(_c3, "Button");
$RefreshReg$(_c4, "LinkButton");
$RefreshReg$(_c5, "NavLinkButton");
$RefreshReg$(_c6, "ExtLink$forwardRef");
$RefreshReg$(_c7, "ExtLink");

window.$RefreshReg$ = prevRefreshReg;
window.$RefreshSig$ = prevRefreshSig;